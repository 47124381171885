import React, { useState, useEffect } from 'react'
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Loader from '../components/Loader'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import axios from '../axios';

const License = () => {
    let navigate = useNavigate();

    const [image, setImage] = useState(null)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)

    function handleTakePhoto(dataUri) {
        setImage(dataUri);
    }

    const submitImageHandler = () => {
        setLoader(true)
        axios.patch("update_driving_licence", { driving_licence: image, driving_lincese_extension: "png" })
            .then((response) => {
                setSuccess(response.data.status.message)
                setImage('')
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.status.message);
                        setLoader(false);
                    }
                }
            })
    }
    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    return (
        <div className='p-5' style={{ textAlign: 'center' }}>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            {(!image) ?
                <Camera
                    onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    isImageMirror={false}
                />
                :
                <>
                    <div>
                        <img src={image} alt="" style={{ margin: "auto", width: '80%', height: 'auto' }} />
                    </div>
                    <br />
                    <button type='button' className='btn btn-primary mb-2' onClick={() => setImage('')}> Clear Photo</button>
                    &nbsp;
                    <button type='submit' className='btn btn-success mb-2' onClick={submitImageHandler}> Upload Photo</button>
                </>
            }
        </div>
    )
}

export default License