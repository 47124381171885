import React, { useState, useEffect } from 'react';
import { LoadScript, GoogleMap, OverlayView, Marker } from "@react-google-maps/api";
import axios from "../axios"
import Loader from '../components/Loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';

const Map = () => {
  let navigate = useNavigate();

  const [vehicleAllList, setVehicleAllList] = useState([])
  const [vehicleNearByList, setVehicleNearByList] = useState([])
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false)
  const [latitute, setLatitute] = useState(-33.947594)
  const [longititute, setLongititute] = useState(18.611929)

  useEffect(() => {
    // setLoader(true)
    axios.get("my_vehicles")
      .then((response) => {
        setVehicleAllList(response.data.data)
        setLoader(false)
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            navigate('/logout')
          } else if (error.response.status === 402) {
            navigate('/unauthorized')
          } else if (error.response || error.response.status !== 200) {
            setError(error.response.data.status.message);
            setLoader(false);
          }
        }
      })
  }, [navigate])

  // const handleMapLatLngClick = (event) => {
  //   setLatitute(event.latLng.lat())
  //   setLongititute(event.latLng.lng())
  // }


  useEffect(() => {
    const interval = setInterval(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          if (latitute !== position.coords.latitude) {
            setLatitute(position.coords.latitude)
          }
          if (longititute !== position.coords.longitude) {
            setLongititute(position.coords.longitude)
          }
        });
      }
    }, 100)

    return () => {
      clearInterval(interval)
    }
  }, [latitute, longititute])

  useEffect(() => {
    if (latitute & longititute) {
      // setLoader(true)
      axios.get("nearby_vehicles?lat=" + latitute + "&lng=" + longititute)
        .then((response) => {
          setVehicleNearByList(response.data.data)
          setLoader(false)
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              navigate('/logout')
            } else if (error.response.status === 402) {
              navigate('/unauthorized')
            } else if (error.response || error.response.status !== 200) {
              setError(error.response.data.status.message);
              setLoader(false);
            }
          }
        })
    }
  }, [navigate, latitute, longititute])

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess('')
    }
  }, [success])

  useEffect(() => {
    if (error !== '') {
      toast.error(error);
      setError('')
    }
  }, [error])

  return (
    <div>
      {loader ? <Loader /> : null}
      <ToastContainer theme="colored" />
      <div className="card-body custom-tab">
        <div className="row">
          <div className="col-md-12" style={{ padding: "5px 0px" }}>
            <LoadScript
              id="script-loader"
              googleMapsApiKey="AIzaSyAdyM6Ax5B6e6ZPzoWxOBBfY3JPIxeIyMI"
              language="en"
              region="us"
            >
              <GoogleMap
                mapContainerClassName="zone-map"
                // center={{ lat: -33.947594, lng: 18.611929 }}
                center={{ lat: latitute, lng: longititute }}
                // onClick={(e) => handleMapLatLngClick(e)}
                zoom={18}
                version="weekly"
                on
              >

                {(vehicleAllList.map((item, index) => (
                  <OverlayView
                    key={index}
                    position={{
                      lat: item.lat,
                      lng: item.lng
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <ul className='callouts'>
                      <li className='callouts--bottom'>{item.imei}</li>
                    </ul>
                  </OverlayView>
                )))}


                <Marker
                  position={{ lat: latitute, lng: longititute }}
                  defaultTitle="Marker"
                  // label="Hii"
                  animation="DROP"
                  shape="square"
                />

                {(vehicleNearByList.map((item, index) => (
                  <OverlayView
                    key={index}
                    position={{
                      lat: item.lat,
                      lng: item.lng
                    }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <ul className='callouts-nearby'>
                      <li className='callouts--bottom-nearby'>{item.imei}</li>
                    </ul>
                  </OverlayView>
                )))}

              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Map