import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from "../axios"
import Loader from "../components/Loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const Product = () => {
  const { imei } = useParams();
  let navigate = useNavigate();

  const [productLists, setProductLists] = useState([])
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false)
  const [Id, setId] = useState(0)
  const [uniqueCode, setUniqueCode] = useState("")
  const [novehicle, setNoVehicle] = useState(false)
  const [noVehicleError, setNoVehicleError] = useState("")

  useEffect(() => {
    setLoader(true)
    axios.put("scan_qr_code", { imei: imei })
      .then((response) => {
        setProductLists(response.data.data.products)
        setSuccess(response.data.status.message)
        setLoader(false)
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            navigate('/logout')
          } else if (error.response.status === 402) {
            navigate('/unauthorized')
          } else if (error.response.status === 400) {
            setNoVehicleError(error.response.data.status.message)
            setNoVehicle(true)
            setLoader(false)
          } else if (error.response || error.response.status !== 200) {
            setError(error.response.data.status.message);
            setLoader(false);
          }
        }
      })
  }, [imei, navigate])

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess('')
    }
  }, [success])

  useEffect(() => {
    if (error !== '') {
      toast.error(error);
      setError('')
    }
  }, [error])

  const makePayment = (getId) => {
    setId(getId)
  }

  const backHandler = () => {
    setId(0)
  }

  const makeCardPayment = () => {
    navigate('/card-payment/' + imei + "/" + Id)
  }

  const submitCashHandler = (e) => {
    e.preventDefault();
    var cashDetails = {
      imei: imei,
      product_id: Id
    }
    setLoader(true)
    axios.post("make_cash_payment", cashDetails)
      .then((response) => {
        setUniqueCode(response.data.data.unique_number)
        setSuccess(response.data.status.message)
        setLoader(false)
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            navigate('/logout')
          } else if (error.response.status === 402) {
            navigate('/unauthorized')
          } else if (error.response || error.response.status !== 200) {
            setError(error.response.data.status.message);
            setLoader(false);
          }
        }
      })
  }

  useEffect(() => {
    const trans = setInterval(() => {
      if (uniqueCode) {
        // setLoader(true)
        axios.get("check_payment_status?unique_number=" + uniqueCode)
          .then((response) => {
            // setLoader(false)
            if (response.data.data.status === 2) {
              navigate('/payment-success')
            }
            if (response.data.data.status === 3) {
              navigate('/payment-fail')
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                navigate('/logout')
              } else if (error.response || error.response.status !== 200) {
                setError(error.response.data.status.message);
                // setLoader(false);
              }
            }
          })
      }
    }, 4000)

    return () => {
      clearInterval(trans)
    }
  }, [uniqueCode, navigate])

  return (
    <div>
      {loader ? <Loader /> : null}
      <ToastContainer theme="colored" />

      {(novehicle) ?
        <div className="row p-5">
          <div className="col-md-12 text-center">
            {/* <h1>THIS VEHICLE HAS NOT BEEN ONLINE RECENTLY AND MAY BE OUT OF ORDER! TRY SCANNING ANOTHER VEHICLE INSTEAD.</h1> */}
            <h1> {noVehicleError} </h1>
            <Link to="/qr-code">
              <button type="button" className='btn btn-warning' style={{ color: "black", alignItems: "center" }}> Scan QR Code</button>
            </Link>
          </div>
        </div>
        :

        <>
          {(uniqueCode) ?
            <div className="row" style={{ height: '88vh' }}>
              <div className="col-md-12 text-center" style={{ display: 'flex' }}>
                <div style={{ margin: 'auto' }}>
                  <h2>Please show code for confirmation</h2>
                  <h1 style={{
                    fontSize: '65px', background: '#fab600',
                    padding: '10px',
                    borderRadius: '15px'
                  }}>{uniqueCode}</h1>
                </div>
              </div>
            </div> :

            <form onSubmit={submitCashHandler}>
              {(!Id) ?

                // <table>
                //   <thead>
                //     <tr>
                //       <th>Amount</th>
                //       <th>Name</th>
                //     </tr>
                //   </thead>
                //   <tbody>
                //   {productLists.map((item, index) => (
                //         <tr 
                //         className='row product' 
                //         style={{ cursor: 'pointer' }} 
                //         key={index} 
                //         onClick={() => makePayment(item.id)}>
                //           <td>R{item.cost}</td>
                //           <td>R{item.name}</td>
                //         </tr>
                //       ))}
                //   </tbody>
                // </table>

                <div className='row p-5'>
                  <div className='col-md-3'></div>
                  <div className='col-md-6'>
                    <h2>Product List</h2>
                    {productLists.map((item, index) => (
                      <div className='row product' style={{ cursor: 'pointer' }} key={index} onClick={() => makePayment(item.id)}>
                        <div className='col-md-12' style={{ textAlign: "center" }}>
                          <b>R{item.cost}</b>
                          :&nbsp;&nbsp;
                          <b>{item.name}</b>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                :
                <div className='row p-5'>
                  <div className="col-md-12" style={{ textAlign: 'center' }}>
                    <button type="submit" className='btn buttonStyle'><b><i className="fa fa-money"></i> Pay With Cash</b></button> <br />
                    <button type="button" className='btn buttonStyle' onClick={makeCardPayment}><b> <i className="fa fa-credit-card"></i> Pay With Card</b></button> <br />
                    <span onClick={backHandler} style={{ cursor: 'pointer' }}>Back</span>
                  </div>
                </div>
              }
            </form>

          }
        </>
      }
    </div >
  )
}

export default Product