import React from "react";
import { Route, Routes } from "react-router-dom";
import { setToken } from "./redux/actions/TokenAction";
import { useDispatch, useSelector } from "react-redux";
import Template from "./components/Template";
import Map from "./containers/Map";
import Dashboard from "./containers/Dashboard";
import Welcome from "./containers/Welcome";
import Login from "./Authentication/Login"
import Register from "./Authentication/Register"
import Logout from "./Authentication/Logout"
import QRCode from "./containers/QRCode";
import Product from "./containers/Product";
// import Test from "./containers/Test";
import CardPayment from "./containers/CardPayment";
import License from "./containers/License";
import Terms from "./containers/Terms";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import PaymentSuccess from "./containers/PaymentSuccess";
import PaymentFail from "./containers/PaymentFail";
import Unauthorized from "./components/Unauthorized";
import axios from "./axios"
import { useNavigate } from "react-router";
import ForgotPassword from "./Authentication/ForgotPassword";
import ResetPassword from "./Authentication/ResetPassword";
import ChangePassword from "./containers/ChangePassword";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (localStorage.getItem("tokenClientEmoby")) {
    dispatch(setToken(localStorage.getItem("tokenClientEmoby")));
  }
  const token = useSelector((state) => state.getToken.token);

  axios.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response.status === 401) {
      if (error.response.config.url !== "login"){
        navigate('/logout')
    }
  } else if (error.response.status === 402) {
    navigate('/unauthorized')
  }
  return Promise.reject(error)
})


var routes = null
if (token) {
  routes = (
    <Routes>
      <Route path="/" element={<Template><Dashboard /></Template>} />
      <Route exact path="/home" element={<Template><Dashboard /></Template>} />
      <Route exact path="/map" element={<Template><Map /></Template>} />
      <Route exact path="/qr-code" element={<Template><QRCode /></Template>} />
      <Route exact path="/s/:imei" element={<Template><Product /></Template>} />
      <Route exact path="/license" element={<Template><License /></Template>} />
      <Route exact path="card-payment/:imei/:Id" element={<Template><CardPayment /></Template>} />
      {/* <Route exact path="/test-data" element={<Template><Test /></Template>} /> */}
      <Route exact path="/payment-success" element={<Template><PaymentSuccess /></Template>} />
      <Route exact path="/payment-fail" element={<Template><PaymentFail /></Template>} />
      <Route exact path="/unauthorized" element={<Template><Unauthorized /></Template>} />
      <Route exact path="/change-password" element={<Template> <ChangePassword/> </Template>} />
      <Route exact path="/logout" element={<Logout />} />
      <Route exact path="/logout/:logoutFlag" element={<Logout />} />
      <Route path="*" element={<Template><Dashboard /></Template>} />
    </Routes>
  );
} else {
  routes = (
    <Routes>
      <Route path="/login" element={<Template><Login /></Template>} />
      <Route path="/forgot-password" element={<Template><ForgotPassword /></Template>} />
      <Route path="/reset-password" element={<Template><ResetPassword /></Template>} />
      <Route path="/register" element={<Template><Register /></Template>} />
      <Route path="/terms" element={<Template><Terms /></Template>} />
      <Route path="/privacy-policy" element={<Template><PrivacyPolicy /></Template>} />
      <Route path="*" element={<Template><Welcome /></Template>} />
    </Routes>
  );
}

return (
  <div>
    {routes}
  </div>
);
}

export default App;
