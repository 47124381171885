import React from 'react'
import Map from './Map'

const Dashboard = () => {
    return (
        <div>
            <Map />
            {/* 
            <iframe
                title='Client'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13944.021264356052!2d26.20083624056476!3d-29.10552441756179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e8fc548f1c86793%3A0x8b86934189431781!2sWestdene%2C%20Bloemfontein%2C%209301%2C%20South%20Africa!5e0!3m2!1sen!2sin!4v1644212322251!5m2!1sen!2sin"
                style={{ height: "100%", width: "100%", top: 0, left: 0, position: "absolute" }}
                allowFullScreen loading="lazy"></iframe> */}
        </div>
    )
}

export default Dashboard