import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../images/logo-m.png"

const Welcome = () => {
    return (
        <div>
            <div className="row bg-dark min-h-100">
                <div className="col-sm-12 mt-5 pt-2 div-center-hr-vr br-btm-red">
                    <div className=" text-center ">
                        <img className="img-fluid for-light mb-3" src={logo} alt="looginpage" />
                        <h1 className="mb-3 txt-warning heading-emoby">WELCOME</h1>
                    </div>
                </div>
                <div className="col-sm-7 div-center-hr-vr br-r-gray pt-3">

                    <div className="p-3 info-scrollable">
                        <h4 className="txt-light head-h4">eMoby offers Electric vehicle rentals</h4>
                        <ul style={{ fontSize: "1.3rem" }} className="ul-head">
                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>Scan QR Code on vehicle using your smart phone.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>Log in using your Google account details.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>Choose the period you would like to rent the vehicle.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>Choose cash or credit card payment.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>Credit card payments are being processed via Bidvest Bank payment gateway.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>All details are stored with Bidvest Bank.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>Once payment has been concluded bike will be activated for the prescribed rental period.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>There are three types of zones red, green and blue zones.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>Blue zone - Rent and return your vehicle here.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>Green zone - Drive anywhere in the green zone.</li>

                            <li><i className="fa fa-angle-double-right txt-primary m-r-10 "></i>Red zone - NO-GO Zone. Vehicle will be disabled if you enter a red zone. Please return to green zone.</li>
                        </ul>
                        <h4 className="mt-3 head-h4">Are you ready to get moving with eMoby?</h4>
                    </div>
                </div>
                <div className="col-sm-5 p-0 div-center-hr-vr">
                    <div className="login-card ">
                        <div>
                            <div>
                                {/* <!-- <a className="logo text-start " href="index.phph "><img className="img-fluid for-light " src="assets/images/logo-m.png " alt="looginpage "><img className="img-fluid for-dark " src="assets/images/logo-m.png " alt="looginpage "></a> --> */}
                            </div>
                            <Link to="/register" className="btn btn-primary btn-block w-100 mb-3 mt-2 " style={{ borderRadius:"14px" }}>
                                <h4 className="txt-dark p-3 head-h4 text-center" style={{ margin: "auto" }}>CREATE AN ACCOUNT</h4>
                            </Link>

                            <Link to="/login" className="btn btn-dark btn-block w-100 mb-2 mt-3 " style={{ borderRadius:"14px" }}>
                                <h4 className="txt-light p-3 head-h4 text-center" style={{ margin: "auto" }}>I HAVE AN ACCOUNT</h4>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome