import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "../axios"
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { setToken } from "../redux/actions/TokenAction";
import { setEmail } from "../redux/actions/EmailAction";
import Loader from '../components/Loader';

// https://www.npmjs.com/package/@react-oauth/google
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: ""
  })

  const [GoogleDetails, setGoogleDetails] = useState({})

  const { email, password } = loginDetails;
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false)

  const handleChange = (e) => {
    setLoginDetails({ ...loginDetails, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    return () => {
    }
  }, [])

  useEffect(() => {
    if (JSON.stringify(GoogleDetails) !== '{}') {
      setLoader(true)
      axios
        .post("login_with_email", GoogleDetails)
        .then((response) => {
          localStorage.setItem("tokenClientEmoby", response.data.data.token);
          dispatch(setToken(response.data.data.token));
          setLoader(false)
          navigate('/')
        })
        .catch((error) => {
          if (error.response.data.status) {
            setError(error.response.data.status.message);
            setLoader(false)
          }
        });
    }
  }, [GoogleDetails, dispatch, navigate])

  useEffect(() => {
    if (success) {
      toast.success(success);
      setSuccess('')
    }
  }, [success])

  useEffect(() => {
    if (error !== '') {
      toast.error(error);
      setError('')
    }
  }, [error])

  useEffect(() => {
    if (typeof (location.state) === 'object') {
      if (location.state !== null) {
        setSuccess(location.state.message)
      }
    } else {
      if (location.state !== null) {
        setError(location.state)
      }
    }
  }, [location])

  const submitForm = (e) => {
    e.preventDefault();
    setError('')
    if (email && password) {
      setLoader(true)
      axios
        .post("login", loginDetails)
        .then((response) => {
          localStorage.setItem("tokenClientEmoby", response.data.data.token);
          localStorage.setItem("emailClientEmoby", response.data.data.email);
          dispatch(setToken(response.data.data.token));
          dispatch(setEmail(response.data.data.email));
          setLoader(false)
          navigate('/')
        })
        .catch((error) => {
          if (error.response.data.status) {
            setError(error.response.data.status.message);
            setLoader(false)
          }
        });
    } else {
      setError('Please enter valid email & password')
    }
  }

  return (
    <div>
      {loader ? <Loader /> : null}
      <ToastContainer theme="colored" />
      <div className="row m-0 min-h-100 div-center-hr-vr">
        <div className="col-12 p-0">
          <div className="login-card">
            <div>
              <div>
                {/* <Link className="logo" to="/">
                  <img className="img-fluid for-light" src={logoM} alt="looginpage" />
                  <img className="img-fluid for-dark" src={logoM} alt="looginpage" />
                </Link> */}
              </div>
              <div className="login-main">
                <form onSubmit={submitForm}
                  className="theme-form">
                  {/* <h4>Sign in to account</h4>
                  <p>Enter your email &amp; password to login</p> */}

                  <div className="form-group">
                    <label
                      className="col-form-label">
                      Email Address
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      id="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      placeholder="Enter Email" />
                  </div>
                  <div className="form-group">
                    <label
                      className="col-form-label">
                      Password
                    </label>
                    <div className="form-input position-relative">
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        id="password"
                        value={password}
                        onChange={handleChange}
                        placeholder="*****" />
                      {/* <div className="show-hide"><span className="show"></span></div> */}
                    </div>
                  </div>
                  <Link to="/forgot-password"><span>Forgot Password ?</span></Link>
                  <div className="text-end mt-3">
                    <button className="btn btn-primary btn-block w-100" type="submit">Sign in</button>
                  </div>
                  <hr />
                  <div style={{ width: '100%' }}>
                    <div style={{ margin: 'auto', width: 'fit-content' }}>
                      <GoogleOAuthProvider clientId="303581049027-7ebk5039el24574d39105eace3lllv0s.apps.googleusercontent.com">
                        <GoogleLogin
                          onSuccess={credentialResponse => {
                            setGoogleDetails(credentialResponse);
                          }}
                          onError={() => {
                            setError('Login Failed');
                          }}
                        />
                      </GoogleOAuthProvider>
                    </div>
                  </div>
                  <p className="mt-4 mb-0 text-center">Not a member? &nbsp;&nbsp;
                    <Link className="ms-2" to="/register">Register here.</Link></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Login