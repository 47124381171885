import axios from "axios";

const instance = axios.create({
  // baseURL: "https://swtprime.com/emoby/api/frontend/",
  // baseURL: "https://emoby-services.epixsa.com/api/frontend/",
  baseURL: "https://services.emoby.co.za/api/frontend/",
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    // 'Access-Control-Allow-Origin': '*',
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("tokenClientEmoby")}`;
      },
    },
  },
});
// console.log(localStorage.getItem('token'))
// instance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token") || "";

export default instance;
