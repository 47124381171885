import { ActionTypes } from "../constants/action-types";

const initialState = {
  email: ""
};

export const emailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_EMAIL:
      return { ...state, email: payload };

    default:
      return state;
  }
};
