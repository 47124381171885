import React from 'react'
import { Link } from 'react-router-dom'

const PaymentSuccess = () => {
  return (
    <div className='paymentResp-wrapper'>
      <h1 className='paymentResp-index'>Payment Successful!</h1>
      <Link to='/'><button className='paymentResp-btn'>Start</button></Link>
    </div>
  )
}

export default PaymentSuccess