import React, { useState, useEffect } from 'react'
import Logo from '../images/large-logo.png'
import eLogo from '../images/emoby-logo.png'
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../components/Loader';
import axios from "../axios"
import { useNavigate } from 'react-router';

const ResetPassword = () => {
    const navigate = useNavigate();
    var url = new URL(window.location.href);
    var token = url.searchParams.get("token");

    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [Password, setPassword] = useState("")
    const [ConfirmPassword, setConfirmPassword] = useState("")
    const [PasswordError, setPasswordError] = useState("")
    const [ConfirmPasswordError, setConfirmPasswordError] = useState("")

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const validate = () => {
        var flag = true;
        setPasswordError("");
        setConfirmPasswordError("")

        if (Password === "") {
            flag = false;
            setPasswordError("New Password field is required")
        }

        if (ConfirmPassword === "") {
            flag = false;
            setConfirmPasswordError("Confirm Password field is required")
        }

        if (Password !== ConfirmPassword) {
            flag = false;
            setError("Password and Confirm Password doesn't match")
        }

        return flag;
    }

    const submitResetPassword = (e) => {
        e.preventDefault();
        const flag = validate();
        if (flag) {
            axios.post("reset_password",
                {
                    reset_password_token: token,
                    new_password: ConfirmPassword
                })
                .then((response) => {
                    setLoader(false)
                    setSuccess(response.data.status.message)
                    setPassword("")
                    setConfirmPassword("")
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false);
                        }
                    }
                })
        }
    }


    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-12 p-0">
                        <div className="login-card">
                            <div>
                                <div>
                                    <div className="logo">
                                        <img className="img-fluid for-light" src={Logo} alt="looginpage" />
                                        <img className="img-fluid for-dark" src={eLogo} alt="looginpage" />
                                    </div>
                                </div>
                                <div className="login-main">
                                    <form className="theme-form"
                                        onSubmit={submitResetPassword}>
                                        <h4>Reset Password</h4>
                                        <p>Enter your email to reset password</p>
                                        <div className="form-group">
                                            <label className="col-form-label">Password</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                value={Password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="*****" />
                                            <small className="form-text text-danger">
                                                {PasswordError}
                                            </small>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">Confirm password</label>
                                            <input
                                                className="form-control"
                                                type="password"
                                                value={ConfirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                placeholder="*****" />
                                            <small className="form-text text-danger">
                                                {ConfirmPasswordError}
                                            </small>
                                        </div>
                                        <div className="form-group mb-0">
                                            <div className="checkbox p-0">
                                                {/* <input id="checkbox1" type="checkbox" /> */}
                                                <Link to="/login">Got to login</Link>
                                            </div>

                                            <div className="text-end mt-4">
                                                <button className="btn btn-primary btn-block w-100" type="submit">Reset Password</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword