import React, { useState, useEffect } from 'react'
import axios from "../axios"
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../components/Loader';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';

const ChangePassword = () => {
    const navigate = useNavigate();
    const emailAddress = useSelector((state) => state.getEmail.email) || localStorage.getItem("emailClientEmoby");

    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [Password, setPassword] = useState("")
    const [OldPassword, setOldPassword] = useState("")
    const [ConfirmPassword, setConfirmPassword] = useState("")
    const [PasswordError, setPasswordError] = useState("")
    const [OldPasswordError, setOldPasswordError] = useState("")
    const [ConfirmPasswordError, setConfirmPasswordError] = useState("")

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const validate = () => {
        var flag = true;
        setPasswordError("")
        setConfirmPasswordError("")
        setOldPasswordError("")

        if (OldPassword === "") {
            flag = false;
            setOldPasswordError("Current Password field is required")
        }

        if (Password === "") {
            flag = false;
            setPasswordError("New Password field is required")
        }

        if (ConfirmPassword === "") {
            flag = false;
            setConfirmPasswordError("Confirm Password field is required")
        }

        if (Password !== ConfirmPassword) {
            flag = false;
            setError("Password and Confirm Password doesn't match")
        }

        return flag;
    }

    const submitPasswordHandler = (e) => {
        e.preventDefault();
        const flag = validate()
        if (flag) {
            const params = {
                email: emailAddress,
                password: OldPassword,
                new_password: Password
            }
            setLoader(true)
            axios.post("change_password", params)
                .then((response) => {
                    setLoader(false)
                    setSuccess(response.data.status.message)
                    setOldPassword("")
                    setPassword("")
                    setConfirmPassword("")
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false);
                        }
                    }
                })
        }
    }


    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="row d-flex align-items-center justify-content-center">
                <div className="col-sm-8">
                    <div className="card" style={{ marginTop:"100px" }}>
                        <div className="card-body">
                            <form className="theme-form"
                                onSubmit={submitPasswordHandler}
                            >
                                <div className="row">
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1">
                                            <h5><i className="fa fa-plus"></i> CHANGE PASSWORD</h5>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="card-header pt-1 text-end">
                                            {/* <button
                                                type="button"
                                                // onClick={cancelHandler}
                                                className="btn btn-emoby-secondary"
                                                style={{ marginRight: "10px" }}
                                            >Cancel
                                            </button> */}

                                            <button className="btn btn-emoby">Submit</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                CURRENT PASSWORD
                                            </label>

                                            <input
                                                className="form-control"
                                                id="OldPassword"
                                                name="OldPassword"
                                                type="password"
                                                placeholder="Enter Current Password"
                                                value={OldPassword}
                                                onChange={(e) => setOldPassword(e.target.value)}
                                            />
                                            <small className="form-text text-danger">
                                                {OldPasswordError}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                NEW PASSWORD
                                            </label>

                                            <input
                                                className="form-control"
                                                id="Password"
                                                name="Password"
                                                type="password"
                                                placeholder="Enter New Password"
                                                value={Password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <small className="form-text text-danger">
                                                {PasswordError}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                CONFIRM PASSWORD
                                            </label>

                                            <input
                                                className="form-control"
                                                id="ConfirmPassword"
                                                name="ConfirmPassword"
                                                type="password"
                                                placeholder="Enter Confirm Password"
                                                value={ConfirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                            />
                                            <small className="form-text text-danger">
                                                {ConfirmPasswordError}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword