import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../images/large-logo.png'
import eLogo from '../images/emoby-logo.png'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../components/Loader';
import axios from "../axios"
import { useNavigate } from 'react-router';

const ForgotPassword = () => {
    const navigate = useNavigate();

    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState("")

    useEffect(() => {
        if (success) {
            toast.success(success, '', 3500);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error, '', 5000);
            setError('')
        }
    }, [error])

    const submitForgotPassword = (e) => {
        e.preventDefault();
        setEmailError("")
        var Emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        var flag = true;
        if (email === "") {
            flag = false;
            setEmailError("Email field is required")
        } else if (!Emailpattern.test(email)) {
            flag = false
            setEmailError("Please enter valid email address");
        }

        if (flag) {
            setLoader(true)
            axios.post("send_reset_password_link", { email: email })
                .then((response) => {
                    setLoader(false)
                    setSuccess(response.data.status.message)
                    setEmail("")
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            navigate('/logout')
                        } else if (error.response.status === 402) {
                            navigate('/unauthorized')
                        } else if (error.response || error.response.status !== 200) {
                            setError(error.response.data.status.message);
                            setLoader(false);
                        }
                    }
                })
        }
    }
    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="row m-0 min-h-100 div-center-hr-vr">
                <div className="col-12 p-0">
                    <div className="login-card">
                        <div>
                            <div>
                                <div className="logo">
                                    <img className="img-fluid for-light" src={Logo} alt="looginpage" />
                                    <img className="img-fluid for-dark" src={eLogo} alt="looginpage" />
                                </div>
                            </div>
                            <div className="login-main">
                                <form className="theme-form" onSubmit={submitForgotPassword}>
                                    <h4>Forgot Password</h4>
                                    <p>Enter your email to send reset password link</p>
                                    <div className="form-group">
                                        <label className="col-form-label">Email Address</label>
                                        <input
                                            className="form-control"
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="test@gmail.com"
                                        />
                                        <small className="form-text text-danger">
                                            {emailError}
                                        </small>
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="checkbox p-0">
                                            {/* <input id="checkbox1" type="checkbox" /> */}
                                            <Link to="/login">Got to login</Link>
                                        </div>

                                        <div className="text-end mt-4">
                                            <button className="btn btn-primary btn-block w-100" type="submit">Send link</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword