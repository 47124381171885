import React from 'react'
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom'

const QRCode = () => {
    const navigate = useNavigate();
    const onScannedHandler = (data) => {
        // const url = 'http://swtprime.com/emoby-client/s/DC786C2A03A8'
        try {
            const url = data.text
            new URL(url);
            const path = url.split('/').reverse()
            if (path[1] === 's') {
                navigate('/' + path[1] + '/' + path[0])
            } else {
                console.log("Something went wrong, please try again!")
            }
        } catch (_) {
            // console.log('catch')
        }
    }

    return (
        <div className="row p-5" style={{ textAlign: 'center' }}>
            <div className='col-md-3'></div>
            <div className="col-sm-6" style={{ display: 'flex' }}>
                <div style={{ width: '100%', margin: 'auto' }}>
                    <QrReader
                        scanDelay={2000}
                        constraints={{ facingMode: 'environment' }}
                        onResult={onScannedHandler}
                        videoStyle={{ width: '100%', height: 'auto', margin: 'auto' }}
                    />
                </div>
            </div>
        </div >
    )
}

export default QRCode