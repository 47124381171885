import React, { useEffect, useState } from 'react'
import axios from "../axios"
import { useParams } from 'react-router'
import Loader from '../components/Loader'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';

const CardPayment = () => {
    const { imei } = useParams();
    const { Id } = useParams();
    let navigate = useNavigate();

    const [cardPaymentDetails, setCardPaymentDetails] = useState({
        cardnumber: '',
        expmonth: '',
        expyear: '',
        cvm: '',
        name: ''
    })
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)
    const [hash, setHash] = useState("")
    const [order_id, setOrder_id] = useState("")
    const [txndatetime, setTxnDateTime] = useState("")
    // const [sharedsecret, setSharedsecret] = useState("")
    const [store_id, setStore_id] = useState("")
    const [currency, setCurrency] = useState("")
    const [chargetotal, setChargetotal] = useState("")

    const { cardnumber, expmonth, expyear, cvm, name } = cardPaymentDetails

    // const [cardError, setCardError] = useState("")

    const handleChange = (e) => {
        var value = e.target.value
        value = value.trim()
        if (e.target.name === "cardnumber") {
            if (value.length > 19) {
                return false
            }
            if (!/[0-9]$/.test(value) && value !== '') {
                return false
            }
            value = value.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
            value = value.trim()
        }

        setCardPaymentDetails({ ...cardPaymentDetails, [e.target.name]: value });
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    // const validate = () => {
    //     var flag = true;
    //     setCardError("")

    //     var check = new RegExp("(\\b\\d{4}\\s\\d{4}\\s\\d{4}\\s\\d{4}\\b)")
    //     if (cardnumber === '') {
    //         flag = false;
    //         setCardError("Enter Card number")
    //     } else if (!check.test(cardnumber)) {
    //         flag = false;
    //         setCardError("Enter valid card number")
    //     }

    //     return flag
    // }

    // const submitCardPayment = (e) => {
    //     // e.preventDefault();
    //     return validate()
    //     // if (flag) {
    //     //     console.log("first")
    //     // }
    // }

    useEffect(() => {
        var data = {
            imei: imei,
            product_id: Id
        }
        setLoader(true)
        axios.post("generate_transaction", data)
            .then((response) => {
                setHash(response.data.data.hash)
                setOrder_id(response.data.data.order_id)
                setTxnDateTime(response.data.data.txndatetime)
                setCurrency(response.data.data.currency)
                setChargetotal(response.data.data.chargetotal)
                // setSharedsecret(response.data.data.sharedsecret)
                setStore_id(response.data.data.store_id)
                setLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        navigate('/logout')
                    } else if (error.response.status === 402) {
                        navigate('/unauthorized')
                    } else if (error.response || error.response.status !== 200) {
                        setError(error.response.data.status.message);
                        setLoader(false);
                    }
                }
            })
    }, [Id, imei, navigate])

    const backHandler = () => {
        navigate(-1)
    }

    return (
        <div>
            {loader ? <Loader /> : null}
            <ToastContainer theme="colored" />
            <div className="row d-flex align-items-center justify-content-center pt-5">
                <div className="col-sm-8">
                    <div className="card">
                        <div className="card-body">
                            {/* <form className='theme-form' onSubmit={submitCardPayment} action="https://www.ipg-online.com/connect/gateway/processing" method='POST'> */}
                            {/* https://www.ipg-online.com/connect/gateway/processing */}
                            <form className='theme-form' method='POST' action='https://www.ipg-online.com/connect/gateway/processing'>
                                <div className='row'>

                                    <div className="col-sm-12 mt-3">
                                        <div className="card-header pt-1">
                                            <div className='row'>
                                                <div className="col-sm-10">
                                                    <h5><i className="fa fa-credit-card"></i> Card Payment</h5>
                                                </div>
                                                &nbsp;
                                                <div className="col-sm-2">
                                                    {/* <h5 className='card-payment-amt'>R  {chargetotal} </h5> */}
                                                    <label>
                                                        Amount: &nbsp;  <b>R {chargetotal}</b>
                                                    </label>
                                                </div>


                                            </div>

                                        </div>
                                    </div>


                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Credit Card Number
                                            </label>

                                            <input
                                                className="form-control" id="cardnumber"
                                                name="cardnumber"
                                                type="text"
                                                placeholder="Enter Credit card number"
                                                value={cardnumber}
                                                onChange={handleChange}

                                                required
                                            />
                                            {/* <small className="form-text text-danger">{cardError}</small> */}
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Name on Card
                                            </label>

                                            <input
                                                className="form-control" id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Enter Name on Card"
                                                value={name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Year
                                            </label>

                                            <input
                                                className="form-control" id="expyear"
                                                name="expyear"
                                                type="number"
                                                min={new Date().getFullYear()}
                                                placeholder="YYYY"
                                                value={expyear}
                                                onChange={handleChange}
                                                required
                                                max={9999}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                Month
                                            </label>

                                            <input
                                                className="form-control" id="expmonth"
                                                name="expmonth"
                                                type="number"
                                                min={1}
                                                max={12}
                                                placeholder="MM"
                                                value={expmonth}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mt-3">
                                        <div className="mb-3">
                                            <label className="col-form-label pt-0" >
                                                CVV
                                            </label>

                                            <input
                                                className="form-control" id="cvm"
                                                name="cvm"
                                                type="number"
                                                placeholder="***"
                                                value={cvm}
                                                onChange={handleChange}
                                                required
                                                min={100}
                                                max={999}
                                            />
                                        </div>
                                    </div>
                                    <input type="hidden" id="txntype" name="txntype" value="sale" />
                                    <input type="hidden" id="chargetotal" name="chargetotal" value={chargetotal} />
                                    <input type="hidden" id="oid" name="oid" value={order_id} />
                                    <input type="hidden" id="full_bypass" name="full_bypass" value="true" />
                                    <input type="hidden" id="timezone" name="timezone" value="GMT" />
                                    <input type="hidden" id="mode" name="mode" value="payonly" />
                                    <input type="hidden" id="txndatetime" name="txndatetime" value={txndatetime} />
                                    <input type="hidden" id="hash" name="hash" value={hash} />
                                    <input type="hidden" id="storename" name="storename" value={store_id} />
                                    <input type="hidden" id="currency" name="currency" value={currency} />

                                    <div className="col-sm-12 mt-3">
                                        <div className="mb-3">
                                            <button className="btn btn-success">Submit</button>&nbsp;
                                            <button className="btn" style={{ background: 'grey', color: '#fff' }} onClick={backHandler}>Back</button>
                                        </div>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPayment