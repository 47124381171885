import React, { useState } from 'react';
import logo from "../images/emoby-logo.png"
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const Template = (props) => {
  const token = useSelector((state) => state.getToken.token);
  const [toggle, setToggle] = useState("")
  const [toggle2, setToggle2] = useState("close_icon")

  const handleToggle = () => {
    setToggle("close_icon")
    setToggle2("")
  }

  const handleCloseToggle = () => {
    setToggle("")
    setToggle2("close_icon")
  }

  return (
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <div className={"page-header " + toggle}>
        <div className="header-wrapper row m-0">
          <div className="header-logo-wrapper col-auto p-0">
            <div className="logo-wrapper">
              <Link to="/">
                <img className="img-fluid" src={logo} alt="" />
              </Link>
            </div>
            <div className="toggle-sidebar" onClick={handleToggle}>
              <i className="fa fa-bars" aria-hidden="true"></i>
            </div>
          </div>
          <div className="nav-right col-8  p-0">
            <Link to="/"><img className="img-fluid for-light" src={logo} alt="" /></Link>
          </div>
        </div>
      </div>
      <div className="page-body-wrapper">
        <div className={"sidebar-wrapper " + toggle2} style={{ width:"100%" }}>
          <div>
            <div className="logo-wrapper d-flex align-items-center justify-content-between">
              <Link to="/" onClick={handleCloseToggle}>
                <img className="img-fluid for-light" src={logo} alt="" />
                <img className="img-fluid for-dark" src={logo} alt="" />
              </Link>
              <div className="back-btn" onClick={handleCloseToggle}>
                <i className="fa fa-angle-left"></i>
              </div>
            </div>
            <div className="logo-icon-wrapper">
              <Link to="/">
                <img className="img-fluid" src={logo} alt="" />
              </Link>
            </div>
            <nav className="sidebar-main">
              <div className="left-arrow" id="left-arrow"><i data-feather="arrow-left"></i></div>
              <div id="sidebar-menu">
                <ul className="sidebar-links" id="simple-bar">
                  {/* <li className="back-btn">
                    <Link to="/">
                      <img className="img-fluid" src={logo} alt="" />
                    </Link>
                    <div className="mobile-back text-end">
                      <span>Back</span>
                      <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
                    </div>
                  </li>
                  <hr className="hr-custom" /> */}
                  <li className="sidebar-list">
                    <ul>

                      {(token) ?
                        <>
                          <li className="sidebar-list">
                            <Link onClick={handleCloseToggle} className="sidebar-link sidebar-title link-nav" to="/map">
                              <i className="fa fa-map-marker" aria-hidden="true"></i>
                              <span>MAP</span>
                            </Link>
                          </li>
                          <hr className="hr-custom" />
                          <li className="sidebar-list">
                            <Link onClick={handleCloseToggle} className="sidebar-link sidebar-title link-nav" to="/qr-code">
                              <i className="fa fa-camera" aria-hidden="true"></i>
                              <span>SCAN QR CODE</span>
                            </Link>
                          </li>
                          <hr className="hr-custom" />
                          <li className="sidebar-list">
                            <Link onClick={handleCloseToggle} className="sidebar-link sidebar-title link-nav" to="/license">
                              <i className="fa fa-upload" aria-hidden="true"></i>
                              <span>LICENSE</span>
                            </Link>
                          </li>
                          <hr className="hr-custom" />
                          <li className="sidebar-list">
                            <Link onClick={handleCloseToggle} className="sidebar-link sidebar-title link-nav" to="/change-password">
                              <i className="fa fa-key" aria-hidden="true"></i>
                              <span>CHANGE PASSWORD</span>
                            </Link>
                          </li>
                          <hr className="hr-custom" />
                          <li className="sidebar-list">
                            <Link className="sidebar-link sidebar-title link-nav" to="/logout/user">
                              <i className="fa fa-power-off" aria-hidden="true"></i>
                              <span> LOGOUT</span>
                            </Link>
                          </li>
                          <hr className="hr-custom" />
                        </>

                        :
                        <>
                          <li className="sidebar-list">
                            <Link onClick={handleCloseToggle} className="sidebar-link sidebar-title link-nav" to="/login">
                              <i className="fa fa-user" aria-hidden="true"></i>
                              <span> LOGIN</span>
                            </Link>
                          </li>
                          <hr className="hr-custom" />

                          <li className="sidebar-list">
                            <Link onClick={handleCloseToggle} className="sidebar-link sidebar-title link-nav" to="/register">
                              <i className="fa fa-user" aria-hidden="true"></i>
                              <span> CREATE AN ACCOUNT </span>
                            </Link>
                          </li>
                          <hr className="hr-custom" />

                          <li className="sidebar-list">
                            <Link onClick={handleCloseToggle} className="sidebar-link sidebar-title link-nav" to="/terms">
                              <i className="fa fa-file" aria-hidden="true"></i>
                              <span> TERMS </span>
                            </Link>
                          </li>
                          <hr className="hr-custom" />

                          <li className="sidebar-list">
                            <Link onClick={handleCloseToggle} className="sidebar-link sidebar-title link-nav" to="/privacy-policy">
                              <i className="fa fa-file" aria-hidden="true"></i>
                              <span> PRIVACY POLICY </span>
                            </Link>
                          </li>
                          <hr className="hr-custom" />
                        </>
                      }


                    </ul>
                  </li>
                </ul>
              </div>
              <div className="right-arrow" id="right-arrow"><i data-feather="arrow-right"></i></div>
            </nav>
          </div>
        </div>
        <div className="page-body" style={{ padding: 0 }}>
          <div className="container-fluid">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Template