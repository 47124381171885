import React from 'react'
import { Link } from 'react-router-dom';

const Unauthorized = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <div className='card' style={{ color: 'red' }}>
                <div className='card-body'>
                    <div>
                        <h1>402: Unauthorized</h1>
                        <br />
                        <Link to="/" className="btn btn-emoby">Home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Unauthorized