import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import axios from "../axios"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../components/Loader"

const Register = () => {
    const navigate = useNavigate();

    const [registerDetails, setRegisterDetails] = useState({
        name: "",
        surname: "",
        email: "",
        password: "",
        confirm_password: "",
        has_driving_lincence: 0
    })
    const { name, surname, email, password, confirm_password, has_driving_lincence } = registerDetails;
    const [nameError, setNameError] = useState("")
    const [surnameError, setSurnameError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [passwordError, setPasswordError] = useState("")
    const [confirmPasswordError, setConfirmPasswordError] = useState("")
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false)

    const handleChange = (e) => {
        var value = e.target.value
        if (e.target.name === "has_driving_lincence") {
            value = (e.target.checked === true) ? 1 : 0
        }
        setRegisterDetails({ ...registerDetails, [e.target.name]: value });
    }

    const validate = () => {
        var flag = true;
        setNameError("")
        setSurnameError("")
        setEmailError("")
        setPasswordError("")
        setConfirmPasswordError("")

        if (name === "") {
            flag = false;
            setNameError("Name field is required")
        }

        if (surname === "") {
            flag = false;
            setSurnameError("Surname field is required")
        }

        var Emailpattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (email === "") {
            flag = false;
            setEmailError("Email field is required")
        } else if (!Emailpattern.test(email)) {
            flag = false
            setEmailError("Please enter valid email address");
        }

        if (password === "") {
            flag = false;
            setPasswordError("Password field is required")
        }

        if (confirm_password === "") {
            flag = false;
            setConfirmPasswordError("Confirm Password field is required")
        }

        if (password !== confirm_password) {
            flag = false;
            setConfirmPasswordError("Password & Confirm Password field should match ")
        }

        return flag;
    }

    const submitRegisterDetails = (e) => {
        e.preventDefault();
        const flag = validate()
        if (flag) {
            setLoader(true)
            axios.post("/register", registerDetails)
                .then((response) => {
                    setSuccess(response.data.status.message)
                    setLoader(false)
                    navigate("/login", {
                        state: {
                            message: response.data.status.message,
                            error: 1
                        }
                    })
                    // setRegisterDetails({
                    //     name: "",
                    //     surname: "",
                    //     email: "",
                    //     password: "",
                    //     confirm_password: "",
                    //     has_driving_lincence: 0
                    // })
                })
                .catch((error) => {
                    setError(error.response.data.status.message);
                    setLoader(false)
                })
        }
    }

    useEffect(() => {
        if (success) {
            toast.success(success);
            setSuccess('')
        }
    }, [success])

    useEffect(() => {
        if (error !== '') {
            toast.error(error);
            setError('')
        }
    }, [error])

    return (
        <div>
            {loader ? <Loader /> : null}
            <div className="row m-0 min-h-100 div-center-hr-vr">
                <div className="col-12 p-0">
                    <div className="login-card">
                        <div>
                            <div>
                                {/* <Link className="logo" to="/">
                                    <img className="img-fluid for-light" src="assets/images/logo-m.png" alt="looginpage" />
                                    <img className="img-fluid for-dark" src="assets/images/logo-m.png" alt="looginpage" /></Link> */}
                            </div>
                            <div className="login-main mt-5">
                                <form onSubmit={submitRegisterDetails}
                                    className="theme-form">
                                    {/* <h4>REGISTER ACCOUNT</h4>
                                    <p>Please Enter your Information</p> */}

                                    <div className="form-group">
                                        <label
                                            className="col-form-label">
                                            Name
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={name}
                                            id="name"
                                            name="name"
                                            onChange={handleChange}
                                            placeholder="First name" />
                                        <small className="form-text text-danger">
                                            {nameError}
                                        </small>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            className="col-form-label">
                                            Last Name
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="surname"
                                            name="surname"
                                            onChange={handleChange}
                                            value={surname}
                                            placeholder="Last name" />
                                        <small className="form-text text-danger">
                                            {surnameError}
                                        </small>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            className="col-form-label">
                                            Email Address
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={handleChange}
                                            placeholder="Enter Email" />
                                        <small className="form-text text-danger">
                                            {emailError}
                                        </small>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            className="col-form-label">
                                            Password
                                        </label>
                                        <div className="form-input position-relative">
                                            <input
                                                className="form-control"
                                                type="password"
                                                name="password"
                                                value={password}
                                                onChange={handleChange}
                                                placeholder="*****" />
                                            {/* <div className="show-hide"><span className="show">                         </span></div> */}
                                            <small className="form-text text-danger">
                                                {passwordError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label
                                            className="col-form-label">
                                            Confirm Password
                                        </label>
                                        <div className="form-input position-relative">
                                            <input
                                                className="form-control"
                                                type="password"
                                                id="confirm_password"
                                                name="confirm_password"
                                                onChange={handleChange}
                                                value={confirm_password}
                                                placeholder="Confirm Password" />
                                            <small className="form-text text-danger">
                                                {confirmPasswordError}
                                            </small>
                                        </div>
                                    </div>
                                    <div className="form-group mb-0">
                                        {/* <div className="checkbox p-0"> */}
                                        {/* <div> */}
                                        <input id="checkbox1" type="checkbox"
                                            className='checkbox-design' /> &nbsp;
                                        <label className="text-muted" >Accept the <Link to="/terms">Terms &amp; Conditions</Link></label>
                                        {/* </div> */}

                                        <div className='form-group mb-0'>
                                            <input
                                                type="checkbox"
                                                className='checkbox-design'
                                                id="has_driving_lincence"
                                                name="has_driving_lincence"
                                                value={has_driving_lincence}
                                                checked={(has_driving_lincence === 1) ? true : false}
                                                onChange={handleChange}
                                            />&nbsp;
                                            <label className="text-muted checkbox p-0" >I have my valid driver's licence with me</label>
                                        </div>
                                        
                                        <div className="text-end mt-3">
                                            <button className="btn btn-primary btn-block w-100" type="submit">Register</button>
                                        </div>
                                    </div>

                                    <p className="mt-4 mb-0 text-center">Already Member ? &nbsp;&nbsp;<Link to="/login" className="ms-2">Sign in here.</Link></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer theme="colored" />
        </div>
    )
}

export default Register